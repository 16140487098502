
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import { apiAgentConfigGetList } from '@/api/setting'
@Component({
    components: {
        LsDialog
    }
})
export default class PayTemplate extends Vue {
    /** S Data **/
    paymentConfigData: Array<Object> = []
    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getPaymentConfigList() {
        apiAgentConfigGetList()
            .then((res: any) => {
                this.paymentConfigData = res
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    goAgentConfigEdit(row: any) {
        this.$router.push({
            path: '/setting/agent/agent_config_edit',
            query: {
                id: row.id
            }
        })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getPaymentConfigList()
    }
}
